<template>
  <div class="customized c-width">
    <p class="title">提交您的需求，我们为您定制</p>
    <a :href="href"
       class="link-btn"
       target="_blank">申请定制</a>
  </div>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.customized {
  height: 350px;
  background: url('../../../assets/images/answer/customized_bg@2x.png')
    no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 36px;
    color: #333;
    font-weight: 500;
  }
  .link-btn {
    margin-top: 78px;
    &:hover {
      background: #fff;
      color: @mainColor;
    }
  }
}
</style>
