<template>
  <div class="scene-list">
    <div class="scene-item"
         v-for="(item, index) in sceneData"
         :key="index">
      <div class="mask"></div>
      <img :src="item.src"
           alt="" />
      <div class="text">
        <div>{{ item.text }}</div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sceneData: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style lang="less" scoped>
.scene-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 40px;
  .scene-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(:first-child) {
      margin-left: 40px;
    }
    .mask {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
    }
    img {
      width: 100%;
    }
    .text {
      position: absolute;
      color: #fff;
      font-size: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .line {
        width: 50px;
        height: 5px;
        margin-top: 15px;
        display: none;
        background: #fff;
        border-radius: 6px;
      }
    }
    &:hover {
      .mask {
        background: rgba(30, 137, 228, 0.6);
      }
      .line {
        display: block;
      }
    }
  }
}
</style>
