<template>
  <div :class="['product-list', { 'c-width': !isProgram }, { program: isProgram }]">
    <a class="product-item"
       v-for="(item, index) in productData"
       :key="index"
       :href="item.href"
       :target="target">
      <img class="item-img"
           v-if="isProgram"
           :src="item.src"
           alt=""
           width="70" />
      <i v-if="!isProgram"
         :class="['icon', item.className]"></i>
      <div class="content">
        <div class="title">{{ item.title }}</div>
        <div class="text">{{ item.text }}</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    productData: {
      type: Array,
      default () {
        return []
      }
    },
    isProgram: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: '_blank'
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.product-list {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  &.program {
    flex-direction: column;
    align-items: left;
    justify-content: center;
    .product-item {
      padding: 10px 30px;
      width: auto;
      background: none;
      &:not(:first-child) {
        margin-top: 20px;
      }
      .content {
        margin-left: 40px;
      }
      &:hover {
        background: #fff;
      }
    }
  }
  .product-item {
    display: flex;
    background: #fff;
    padding: 30px 40px;
    width: 500px;
    align-items: center;
    .icon {
      display: inline-block;
      &.live {
        width: 40px;
        height: 30px;
        background: url('../../../assets/images/live-default.png') no-repeat;
        background-size: cover;
      }

      &.vod {
        width: 44px;
        height: 33px;
        background: url('../../../assets/images/vod-default.png') no-repeat;
        background-size: cover;
      }
      &.chat {
        width: 40px;
        height: 32px;
        background: url('../../../assets/images/chat-default.png') no-repeat;
        background-size: cover;
      }
    }
    .content {
      margin-left: 50px;
      .title {
        font-size: 24px;
        color: #555;
        font-weight: 500;
      }
      .text {
        margin-top: 10px;
        font-size: 16px;
        color: #666;
      }
    }
    &:hover {
      box-shadow: @boxShadow;
      .icon {
        &.live {
          width: 40px;
          height: 30px;
          background: url('../../../assets/images/live-hover.png') no-repeat;
          background-size: cover;
        }

        &.vod {
          width: 44px;
          height: 33px;
          background: url('../../../assets/images/vod-hover.png') no-repeat;
          background-size: cover;
        }
        &.chat {
          width: 40px;
          height: 32px;
          background: url('../../../assets/images/chat-hover.png') no-repeat;
          background-size: cover;
        }
      }
      .title,
      .text {
        color: @mainColor;
      }
    }
  }
}
</style>
