<template>
  <div :class="['banner-wrap', bannerData.ways]">
    <div class="banner c-width">
      <div class="text-wrap">
        <div class="title">{{ bannerData.title }}</div>
        <div class="content">
          <div
            class="text"
            v-for="(item, index) in bannerData.text"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="btn-wrap">
        <a :href="bannerData.href2" class="link-btn" target="_blank">{{
          bannerData.text2
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerData: {
      type: Object,
      default () {
        return []
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.banner-wrap {
  height: 546px;
  &.live-answer {
    background: url('../../../assets/images/banner_bg@2x.png') no-repeat;
    background-size: cover;
  }
  &.live-mini-program {
    background: url('../../../assets/images/program/banner_bg@2x.png') no-repeat;
    background-size: cover;
    .banner {
      background: url('../../../assets/images/program/s@2x.png') no-repeat;
      background-size: cover;
    }
  }
  &.enjoy {
    .banner {
      background: url('../../../assets/images/enjoy/banner.png') no-repeat;
      background-size: contain;
      background-position-x: right;
    }
  }
  .banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .text-wrap {
      text-align: center;
      .title {
        font-size: 36px;
        font-weight: 600;
        color: #333;
      }
      .content {
        margin: 40px 0 50px;
        .text {
          font-size: 24px;
        }
      }
    }
    .btn-wrap {
      display: flex;
      a:nth-of-type(2) {
        margin-left: 30px;
      }
      a:hover {
        background: #fff;
        color: @mainColor;
      }
    }
  }
}
</style>
